'use strict';
/* 
Catégories one Trust : 
    Cookies non soumis à consentement	C0001
    Cookies de performance	            C0002
    Cookies de fonctionnalité	        C0003
    Cookies pour une publicité ciblée	C0004
    Cookies « réseaux sociaux »	        C0005 
*/

const RGPD = {
    text: {
        engage: {
            fr: "Vous devez activer les cookies pour accéder à ce service.",
            en: "Please accept cookies to access this service."
        },
        cta: {
            fr: "Modifier",
            en: "Update"
        }
    },
    togglePanelBtns: [],
    init: function () {

        // Init links #rgpd to open preference panel 
        RGPD.togglePanelBtns = document.querySelectorAll('a[href="#rgpd"]')

        if (RGPD.togglePanelBtns.length) {
            RGPD.togglePanelBtns.forEach(btn => {
                btn.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (Optanon) {
                        Optanon.ToggleInfoDisplay()
                    }
                })
            })
        }

        window.addEventListener('load', () => {
            // Init Consent on Load 
            RGPD.checkConsent(false)

            // Consent change event
            if (Optanon) {
                Optanon.OnConsentChanged(() => {
                    RGPD.checkConsent(false)
                })
            }
        })
    },
    checkConsent: function (useRefresh) {

        let consentElements = document.querySelectorAll('[class^="optanon-category"]');

        consentElements.forEach(el => {

            let parent = el.parentElement
            let codes = el.getAttribute('data-onetrust-categories');

            if (codes) {
                codes = codes.split('-')
                let isActive = true;

                codes.forEach(code => {
                    if (!OptanonActiveGroups.includes(code)) isActive = false
                })

                // Regular iframes
                if (el.tagName === "IFRAME") {
                    if (!isActive && !parent.querySelector('.rgpd-disabled')) {
                        let engageDiv = RGPD.createEngageDiv()
                        parent.appendChild(engageDiv)
                    }
                    else if (isActive && parent.querySelector('.rgpd-disabled')) {
                        parent.removeChild(parent.querySelector('.rgpd-disabled'))
                    }
                }

                // Blockquotes (instagram + tiktok)
                if (el.classList.contains("tiktok-embed") || el.classList.contains("instagram-media")) {

                    if (!isActive && !parent.querySelector('.rgpd-disabled')) {
                        el.classList.add("hide")
                        let engageDiv = RGPD.createEngageDiv()
                        parent.appendChild(engageDiv)
                    }
                    else if (isActive && parent.querySelector('.rgpd-disabled')) {
                        el.classList.remove("hide")
                        parent.removeChild(parent.querySelector('.rgpd-disabled'))
                    }
                }

                // Twitter
                if (el.classList.contains("twitterembed-canvas")) {
                    let twitterScript = document.getElementById('twitterScript')
                    const codes = twitterScript.getAttribute('data-onetrust-categories').split('-')
                    let isActive = true;

                    codes.forEach(code => {
                        if (!OptanonActiveGroups.includes(code)) isActive = false
                    })

                    if (!isActive && !parent.querySelector('.rgpd-disabled')) {
                        el.classList.add("hide")
                        let engageDiv = RGPD.createEngageDiv()
                        parent.appendChild(engageDiv)
                    }
                    else if (isActive) {
                        el.classList.remove("hide")

                        if (parent.querySelector('.rgpd-disabled')) {
                            parent.removeChild(parent.querySelector('.rgpd-disabled'))
                        }
                        
                        if (window.twttr) {
                            RGPD.createTweet(el)
                        }
                        else {
                            twitterScript.addEventListener('load', () => {
                                RGPD.createTweet(el)
                            })
                        }
                    }
                }
            }
        })

        if (useRefresh) window.location.reload()
    },
    createTweet: function (el) {
        el.innerHTML = ''
        twttr.widgets.createTweet(
            el.getAttribute('tweetid'),
            el,
            {
                theme: el.getAttribute('theme'),
                cards: 'show',
                conversation: 'none',
                lang: 'en',
                dnt: true,
                width: `${el.getAttribute('data-width')}`,
                height: `${el.getAttribute('data-height')}`,
                align: 'left'
            }
        );
    },
    createEngageDiv: function () {
        const div = document.createElement('div')
        div.classList.add('rgpd-disabled')
        let lang = document.querySelector('html').getAttribute('lang').includes('fr') ? 'fr' : 'en'

        div.innerHTML = `
            <span>${RGPD.text.engage[lang]}</span>
        `

        const button = document.createElement('button');
        button.textContent = RGPD.text.cta[lang]
        button.addEventListener('click', () => Optanon.ToggleInfoDisplay())
        div.appendChild(button)

        return div
    }
};

export default RGPD