'use strict';

const TitleSize = {
    els: null,
    init: function (el) {
        if (el) {
            TitleSize.handler(el);
        } else {
            TitleSize.els = document.querySelectorAll('.f-event__content-title');
            if (TitleSize.els && TitleSize.els.length > 0) {
                TitleSize.els.forEach(el => {
                    TitleSize.handler(el);
                });
            }
        }
    },
    handler: function (el) {
        const text = el;

        // 36 & +
        if (text.innerHTML.length > 35) {
            el.classList.add('smaller--');
        }
        // 23 - 35
        if (text.innerHTML.length > 22 && text.innerHTML.length < 36) {
            el.classList.add('medium--');
        }
    }
};

export default TitleSize;
