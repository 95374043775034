// Components

import Accordion from "./blocks/bloc_accordeon";
import Animations from "./components/Animations";
import AudioPreview from "./blocks/bloc_audiopreview"
import Card from "./components/Card";
import Casting from "./blocks/bloc_casting";
import Cursor from "./components/Cursor";
import EventExperience from "./blocks/bloc_event_experience";
import Form from "./components/Form";
import Header from "./components/Header";
import HeaderFlex from "./blocks/bloc_headerflex";
import Highlight from "./blocks/bloc_highlight";
import Immersion from "./blocks/bloc_immersion";
import Newsletter from "./components/Newsletter";
import Popin from "./components/Popin";
import RGPD from "./components/RGPD";
import Select from "./components/Select";
import SliderMulti from "./blocks/bloc_slider_multi";
import SliderSingle from "./blocks/bloc_slider_single_type3";
import StickyTicketing from "./components/StickyTicketing";
import Title from "./components/Title";
import TitleSize from "./components/TitleSize";
import Video from "./blocks/bloc_video"

const main = {
    init: function () {
        if(window.innerWidth > 1024){
            const cursor = new Cursor();            
        }
        RGPD.init();
        Title.init();
        TitleSize.init();
        Card.init();
        Highlight.init();
        EventExperience.init();
        Newsletter.init();
        Form.init();
        Animations.init();
        Header.init();
        SliderSingle.init();
        Accordion.init();
        Casting.init();
        SliderMulti.init();
        Video.init();
        Popin.init();
        Select.init();
        HeaderFlex.init();
        AudioPreview.init();
        StickyTicketing.init();
        Immersion.init(); 
    },

};

export default main