'use strict';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const HeaderFlex = {
    el: null,
    img: null,
    blockPos: [
        {
            top: 15,
            bottom: 50,
        },
        {
            top: 30,
            bottom: 90,
        },
        {
            top: 0,
            bottom: 80
        },
        {
            top: 20,
            bottom: 100
        },
        {
            top: 60,
            bottom: 100,
        },
        {
            top: 25,
            bottom: 45
        }
    ],
    init: function () {


        HeaderFlex.el = document.querySelector('.f-headerflex')
        HeaderFlex.img = document.querySelector('.f-headerflex__ctn-img')

        if (HeaderFlex.el && HeaderFlex.img) {

            window.addEventListener('load', () => {

                if (window.scrollY === 0) {
                    let animate = true;
                    let breadcrumbs = document.querySelector('.c-breadcrumb')

                    ScrollTrigger.create(
                        {
                            trigger: HeaderFlex.el,
                            end: "+=500",
                            pin: true,
                            onEnter: () => {
                                gsap.to(HeaderFlex.img, {
                                    duration: 1,
                                    ease: "power2.inOut",
                                    opacity: 1,
                                    clipPath: HeaderFlex.generateClipPath
                                })
                                if (breadcrumbs) breadcrumbs.classList.add('fixed--')
                            },
                            onUpdate: (self) => {
                                if (animate) {
                                    gsap.to(HeaderFlex.img, {
                                        clipPath: () => HeaderFlex.generateClipPath(self.progress)
                                    })
                                }
                            },
                            onLeave: () => {
                                animate = false;
                                gsap.to(HeaderFlex.img, {
                                    clipPath: 'unset'
                                })
                                HeaderFlex.img.classList.remove('animate--')
                                if (breadcrumbs) breadcrumbs.classList.remove('fixed--')
                            },
                            onEnterBack: () => {
                                if (breadcrumbs) breadcrumbs.classList.add('fixed--')
                            }
                        }
                    )
                }
                else {
                    HeaderFlex.img.classList.remove('animate--')
                }
            })
        }
    },
    generateClipPath: function (progress) {
        if (!progress) progress = 0;

        let coordinates = ''
        let blocks = (window.innerWidth > 767) ? HeaderFlex.blockPos : HeaderFlex.blockPos.slice(0, 3) 
        let nbrBlocks = blocks.length

        // Tops
        for (let i = 0; i < nbrBlocks; i++) {

            let posY = blocks[i].top
            let step = progress * posY

            coordinates += `${(100 / nbrBlocks) * i}% ${posY - step}%, \n`
            if (i + 1 <= nbrBlocks) {
                coordinates += `${(100 / nbrBlocks) * (i + 1)}% ${posY - step}%, \n`
            }
        }

        // Bottoms
        for (let i = nbrBlocks - 1; i >= 0; i--) {

            let posY = blocks[i].bottom
            let step = progress * (100 - posY)

            coordinates += `${(100 / nbrBlocks) * (i + 1)}% ${posY + step}%, \n`
            coordinates += `${(100 / nbrBlocks) * (i)}% ${posY + step}%${i > 0 ? ',' : ''} \n`
        }

        return `polygon(${coordinates})`
    }
};

export default HeaderFlex;