'use strict';

const StickyButton = {
    elts: null,
    init: function () {
        StickyButton.elts = document.querySelectorAll('.c-stickyButton');  
        if(StickyButton.elts && StickyButton.elts.length > 0) {
            StickyButton.makeSticky();
            window.addEventListener('wheel', (e) => {
                StickyButton.makeSticky();
            })
            StickyButton.elts.forEach(elt => {
                if(elt.querySelector('a.c-button')) {
                    elt.querySelector('a.c-button').addEventListener('click', (e) => {
                        elt.querySelector('a.c-button').classList.add('loading');
                    });
                    elt.querySelector('a.c-button').addEventListener('blur', (e) => {
                        elt.querySelector('a.c-button').classList.remove('loading');
                    });
                }
            });
        }
        
        
    },
    makeSticky: function() {
        StickyButton.elts.forEach(elt => {
            if(elt.closest('.stickyContainer--').getBoundingClientRect().bottom <= window.innerHeight) {
                if(!elt.classList.contains('sticky--')) {
                    elt.classList.add('sticky--')
                };
            }
            else {
                elt.classList.remove('sticky--');
            }
        });
    },
    disableButton:function(elt) {
        elt.setAttribute('disabled', true);
    }
};


export default StickyButton;
