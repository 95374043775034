'use strict';

import '@lottiefiles/lottie-player';

import SplitType from "split-type";
import gsap from "gsap";

// import { create } from '@lottiefiles/lottie-interactivity';

const Animations = {
    init: function () {
        let bookButtons = document.querySelectorAll('.c-button.book--.js--');
        if (bookButtons && bookButtons.length > 0) {
            bookButtons.forEach(button => {
                Animations.handleHover(button);
            });
        }


        let tickettingButtons = document.querySelectorAll('.c-sticky-ticketing.js-- a');
        if (tickettingButtons && tickettingButtons.length > 0) {
            tickettingButtons.forEach(button => {
                Animations.handleHover(button);
            });
        }

        let headerAnimations = document.querySelectorAll('.header__animations.js--')
        if(headerAnimations && headerAnimations.length > 0) {
            headerAnimations.forEach(button => {
                Animations.handleHover(button)
            })
        }

        let fromRight = document.querySelectorAll('.js-fromRight');
        if (fromRight && fromRight.length > 0) {
            fromRight.forEach(title => {
                const parentSplit = new SplitType(title, {
                    type: "lines",
                    lineClass: "split-parent"
                });

                gsap.to(title.querySelectorAll('.split-parent'), {
                    opacity: 1,
                    x: 0,
                    ease: "power4",
                    stagger: .15,
                    delay: .05,
                    duration: .3,
                });
                gsap.to(title.querySelectorAll('.split-parent'), {
                    opacity: 1,
                    ease: "power4",
                    stagger: .15,
                    delay: .15,
                    duration: .25,
                });

                gsap.to(title, {
                    opacity: 1,
                    duration: 0,
                });
            });
        }

        let fromBottomLetters = document.querySelectorAll('.js-fromBottomLetters');
        if (fromBottomLetters && fromBottomLetters.length > 0) {
            fromBottomLetters.forEach(title => {
                let trigger = title.closest('.js-ctn'); 
                let allowAllMethods = true; 
                if(document.querySelector('.f-immersion')) {
                    trigger = document.querySelector('.f-immersion')
                    allowAllMethods = false
                }
                const text = new SplitType(title);

                gsap.timeline({
                    scrollTrigger: {
                        trigger: trigger,
                        start: !allowAllMethods && `bottom+=${window.innerHeight} 80%`,
                        bottom: !allowAllMethods && `bottom+=${window.innerHeight * 2} 20%`,
                        onEnter: function () {
                            show()
                        },
                        onEnterBack: function () {
                            if(allowAllMethods) show()
                        },
                        onLeave: function () {
                            if(allowAllMethods) hide()
                        },
                        onLeaveBack: function () {
                            if(allowAllMethods) hide()
                        }, 
                    }
                })

                function show() {
                    gsap.to(title.querySelectorAll('.char'), {
                        y: 0,
                        stagger: .05,
                        delay: .05,
                        duration: .1,
                        overwrite: true
                    });
                }

                function hide() {
                    gsap.set(title.querySelectorAll('.char'), {
                        y: 300,
                        overwrite: true
                    });
                }
            });

        }


        let fromTop = document.querySelectorAll('.js-fromTop');
        if (fromTop && fromTop.length > 0) {
            fromTop.forEach(title => {

                const text = new SplitType(title);

                gsap.to(title, {
                    opacity:1,
                    duration: .2,
                });
                gsap.to(title.querySelectorAll('.char'), {
                    y: 0,
                    stagger: .05,
                    //delay: .05,
                    duration: .4,
                });
            });

        }

        let bounce = document.querySelectorAll('.js-bounce');
        if (bounce && bounce.length > 0 ){
            bounce.forEach( elt => {
                gsap.fromTo( elt, 
                {
                    translateY: -50,
                    opacity: 0,
                }, 
                {
                    ease: 'bounce.out',
                    duration: 1,
                    delay: 2,
                    translateY: 0, 
                    opacity: 1
                })
            })
        }
    },
    handleHover: function (button) {
        const player = button.querySelector("lottie-player");
        if (player) {
            player.addEventListener("ready", () => {
                button.addEventListener("mouseover", () => {
                    player.setDirection(1);
                    player.play();
                });

                button.addEventListener("mouseleave", () => {
                    player.setDirection(-1);
                    player.play();
                });
            });

        }
    }
};

export default Animations;