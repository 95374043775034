'use strict';

import '@lottiefiles/lottie-player';

import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const Highlight = {
    els: null,
    init: function () {
        Highlight.els = document.querySelectorAll('.f-highlight');
        if (Highlight.els && Highlight.els.length > 0) {
            gsap.utils.toArray(".f-highlight").forEach(function(elem) {

                gsap.set(elem.querySelector('.h3'), {
                    opacity:0,
                    y:10
                });
                gsap.set(elem.querySelectorAll('p'), {
                    opacity:0,
                    y:50
                });

                const player = elem.querySelector("lottie-player");

                /* SAFARI BLURRY SVG FIX : REMOVE TRANSFORM: TRANSLATE3D() FROM INLINE STYLE */
                player.addEventListener('ready', () => {
                    player.renderRoot.querySelector('svg').style = 'width: 100%;height: 100%;content-visibility: visible;'
                })
                function play(){

                    if (player) {
                       setTimeout(() => {
                            player.setDirection(1);
                            player.play();
                        }, 500);
                    }
                }

                function hide(){
                    if (player) {
                        player.setDirection(-1);
                        player.play();
                    }
                }

                gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        start: 'center-=20% center',
                        scrub: true,
                        onEnter: () => play(),
                        onEnterBack: () => play(),
                        onLeave: () => hide(), // assure that the element is hidden when scrolled into view
                        onLeaveBack: () => hide()
                    }
                });

                gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        start: 'top bottom',
                        scrub: true,
                    }
                })
                    .to(elem.querySelector('.h3'), {opacity:1, y:0, duration:.25}, '-=.35')
                    .to(elem.querySelectorAll('p'), {opacity:1, y:0, stagger:0.05, delay:0.05, duration:.25}, '-=.35');
            });
        }
    },
};

export default Highlight;
