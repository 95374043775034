export const checkDevice = () => {
    let device; 

    if(window.innerWidth > 768){
        device = 'desktop'
    }
    else {
        device = 'mobile'
    }

    return device
}