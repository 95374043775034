'use strict';

const Casting = {
    init: function () {
        const readMoreContent = document.querySelectorAll('.f-casting__card-desc')

        if (readMoreContent.length > 0) {
            readMoreContent.forEach(el => {
               const toggle = el.querySelector('button');
               const content = el.querySelector('.c-wysiwyg');

               if(content.clientHeight < 115){ // = approx 6 lines
                    toggle.disabled = true
               }
               else {
                   toggle.addEventListener('click', () => el.classList.toggle('active'))
               }

            });
        }
    },
};

export default Casting;