'use strict';

import Popin from "./Popin";

const Header = {
    el: null,
    init: function () {
        Header.el = document.querySelector('.header');
        if (Header.el) {
            window.addEventListener('scroll', () => {
                if (window.scrollY === 0) {
                    if (Header.el.classList.contains('scrolled')) {
                        Header.el.classList.remove('scrolled');
                    }
                } else {
                    Header.el.classList.add('scrolled');
                }
            });

            const buttonMenu = Header.el.querySelector('.header__toggle');
            if (buttonMenu) {
                buttonMenu.addEventListener('click', () => {
                    Header.handleMenu();
                });
            }

            const itemsWithSubmenu = Header.el.querySelectorAll('li.menu-item-has-children > a');
            if (itemsWithSubmenu && itemsWithSubmenu.length > 0) {
                itemsWithSubmenu.forEach(item => {
                    Header.handleSubmenu(item);
                });
            }

            const searchBtn = Header.el.querySelector('.header__search');
            const searchPopin = document.querySelector('#searchPopin')
            if (searchBtn && searchPopin) {
                searchBtn.addEventListener('click', function (e) {
                    e.preventDefault();
                    Popin.showPopin(searchPopin)
                });
            }

            /* GTAG */
            const n1items = Header.el.querySelectorAll('.menu > li:not(.menu-item-has-children) a');
            if (n1items.length) {
                n1items.forEach(n1item => {
                    n1item.addEventListener('click', (e) => {
                        e.preventDefault()
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'menu_click',
                            category: n1item.textContent,
                            page: window.location.href
                        });

                        window.location = e.target.href
                    })
                })
            }

            const n1itemsWithChildren = Header.el.querySelectorAll('.menu-item-has-children')
            if (n1itemsWithChildren.length) {
                n1itemsWithChildren.forEach( item => {
                    const category = item.querySelector('a').textContent
                    const children = item.querySelectorAll('.sub-menu a')
                    if(children.length) {
                        children.forEach(child => {
                            const subCategory = child.textContent;
                            if(category && subCategory){
                                child.addEventListener('click', (e) => {
                                    e.preventDefault()
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        event: 'menu_click',
                                        category: category,
                                        subCategory: child.textContent,
                                        page: window.location.href
                                    });
                                    
                                    window.location = e.target.href
                                })

                            }
                        })
                    }
                })
            }
            /* ./GTAG */
        }
    },
    handleMenu: function () {
        const stickyBtn = document.querySelector('.c-sticky-ticketing')
        if (Header.el.classList.contains('menuOpened')) {
            Header.handleMenuClose();
            stickyBtn.classList.remove('move-top')
        } else {
            Header.handleMenuOpen();
            stickyBtn.classList.add('move-top')
        }
    },
    handleMenuOpen: function () {
        Header.el.classList.add('menuOpening');
        document.documentElement.classList.add('menuOpened')
        setTimeout(() => {
            Header.el.classList.remove('menuOpening');
            Header.el.classList.add('menuOpened')
        }, 800)
    },
    handleMenuClose: function () {
        Header.el.classList.add('menuClosing');
        Header.el.classList.remove('menuOpened');
        document.documentElement.classList.remove('menuOpened')

        setTimeout(() => {
            Header.el.classList.remove('menuClosing');
        }, 800)
    },
    handleSubmenu: function (a) {
        a.addEventListener('click', (e) => {
            e.preventDefault();
            const li = a.parentElement;
            const submenu = li.querySelector('.sub-menu');
            li.classList.toggle('active');
            submenu.classList.toggle('active');
        });
    }
};

export default Header;
