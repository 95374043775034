'use strict';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Card = {
    els: null,
    init: function () {
        Card.els = document.querySelectorAll('.c-card');
        if (Card.els && Card.els.length > 0) {
            gsap.utils.toArray(".c-card").forEach(function(elem) {
               // hide(elem); // assure that the element is hidden when scrolled into view
                gsap.set(elem, {y:150});

                ScrollTrigger.create({
                    trigger: elem,
                    //  markers: true,
                    onEnter: function() { animateFrom(elem) },
                    onEnterBack: function() { animateFrom(elem) },
                    onLeave: function() { hide(elem) }, // assure that the element is hidden when scrolled into view
                    onLeaveBack: function() { hide(elem) }
                });
            });
        }
    },
};

function animateFrom(elem) {
    gsap.fromTo(elem, {y: 150}, {
        duration: 1.25,
        y: 0,
        //opacity: 1,
        ease: "expo",
        overwrite: "auto"
    });
}

function hide(elem) {
    gsap.fromTo(elem, {y: 0}, {
        duration: 1.75,
        y: 150,
        //opacity: 1,
        ease: "expo",
        overwrite: "auto"
    });
}


export default Card;
