'use strict';

import '@lottiefiles/lottie-player';
import { checkDevice } from '../utils/checkDevice';

const AudioPreview = {
    el: null,
    isPlaying: false,
    button: null,
    player: null,
    lottie: null,
    stickyConfig: {
        desktop: {
            scrollDown: -60,
        },
        mobile: {
            scrollDown: 5,
        }
    },
    device: checkDevice(),
    init: function () {
        AudioPreview.el = document.querySelector('.c-audiopreview')
        AudioPreview.button = document.querySelector('.c-audiopreview__button');
        AudioPreview.player = document.querySelector('.c-audiopreview audio')
        AudioPreview.lottieStart = document.querySelector('.c-audiopreview .animation-start')
        AudioPreview.lottieLoop = document.querySelector('.c-audiopreview .animation-loop')

        if (AudioPreview.button && AudioPreview.player && AudioPreview.lottieStart) {

            window.addEventListener('resize', () => { AudioPreview.device = checkDevice() })
            window.addEventListener('scroll', AudioPreview.handleSticky);

            AudioPreview.lottieStart.addEventListener("ready", () => {
                AudioPreview.button.addEventListener('click', AudioPreview.handlePlayPause)
                AudioPreview.player.addEventListener('ended', AudioPreview.handlePlayPause)
                AudioPreview.button.addEventListener('mouseenter', AudioPreview.handleMouseEnter)
                AudioPreview.button.addEventListener('mouseleave', AudioPreview.handleMouseLeave)
            })
        }
    },
    handlePlayPause: function () {
        if (AudioPreview.isPlaying) {
            AudioPreview.isPlaying = false;
            AudioPreview.lottieLoop.stop()
            AudioPreview.el.classList.remove('active')
            AudioPreview.player.pause()
            AudioPreview.handleMouseLeave()
        }
        else {
            AudioPreview.isPlaying = true;
            AudioPreview.el.classList.add('active')
            AudioPreview.player.currentTime = 0;
            AudioPreview.player.play()
            AudioPreview.lottieLoop.play()
        }
    },
    handleSticky: function () {
        let maxPosY = AudioPreview.stickyConfig[AudioPreview.device].scrollDown;
        let parentPos = AudioPreview.el.getBoundingClientRect().top
        if (maxPosY >= parentPos) {
            AudioPreview.el.classList.add('sticky--')
        }
        else {
            AudioPreview.el.classList.remove('sticky--')
        }
    },
    handleMouseEnter: function () {
        AudioPreview.lottieStart.setDirection(1);
        AudioPreview.lottieStart.play()
    },
    handleMouseLeave: function () {
        AudioPreview.lottieStart.setDirection(-1);
        AudioPreview.lottieStart.play()
    }

};


export default AudioPreview;