"use strict";

import "@lottiefiles/lottie-player";

import SplitType from "split-type";
import gsap from "gsap";

const SCROLL_TIMING = 5000; 

const StickyTicketing = {
  el: null,
  initAnimationIsPlaying: true,
  scrollAnimationIsPlaying: false,
  init: function () {
    if (window.innerWidth < 1025) return;

    StickyTicketing.el = document.querySelector(".c-sticky-ticketing");

    if (StickyTicketing.el) {
      const splitChars = new SplitType(
        StickyTicketing.el.querySelector(".c-sticky-ticketing__ctn span"),
        {
          type: "chars",
          lineClass: "split-parent",
        }
      );

      const children = {
        lineTop: StickyTicketing.el.querySelector(".line-top"),
        lineBottom: StickyTicketing.el.querySelector(".line-bottom"),
        text: StickyTicketing.el.querySelector(".c-sticky-ticketing__ctn span"),
        icon: StickyTicketing.el.querySelector("i"),
        chars: StickyTicketing.el.querySelectorAll(".char"),
        player: StickyTicketing.el.querySelector("lottie-player"),
      };

      StickyTicketing.initLandingAnimation(children);
      StickyTicketing.initScrollAnimation(children);
    }
  },
  initLandingAnimation: function (els) {
    const { lineTop, lineBottom, chars, icon } = els;
    let tl = gsap.timeline();

    // Set
    tl.set(lineTop, {
      height: "10px",
      bottom: "80%",
    })
      .set(lineBottom, {
        height: "10px",
        top: "80%",
      })
      .set(chars, {
        top: "30px",
      })
      .set(StickyTicketing.el, {
        height: "100px",
        scale: 0.1,
      });

    // Animate
    window.addEventListener("DOMContentLoaded", () => {
      tl.to(StickyTicketing.el, {
        scale: 2,
        duration: 0.5,
        delay: 1,
      })
        .to(
          icon,
          {
            rotate: "10deg",
            duration: 0.3, 
          },
          "-=0.5"
        )
        .to(StickyTicketing.el, {
          scale: 1,
          duration: 0.3
        })
        .to(
          icon,
          {
            rotate: 0,
            duration: 0.3
          },
          "-=0.5"
        )
        .to(StickyTicketing.el, {
          height: "230px",
          duration: 0.3
        })
        .to(
          lineTop,
          {
            bottom: "100%",
            height: "67px",
            duration: 0.3
          },
          "<"
        )
        .to(
          lineBottom,
          {
            top: "100%",
            height: "67px",
            duration: 0.3
          },
          "<"
        )
        .to(chars, {
          top: 0,
          stagger: {
            amout: 0.2,
            each: 0.03,
          },
          onComplete: () => (StickyTicketing.initAnimationIsPlaying = false),
        });
    });
  },
  initScrollAnimation: function (els) {
    const tl = gsap.timeline({
      paused: true,
    });

    const { lineTop, lineBottom, chars, icon, player } = els;

    tl.to(icon, {
      rotate: "10deg",
      duration: 0.3,
      onStart: () => {
        player.setDirection(1);
        player.play();
      },
      onComplete: () => {
        player.setDirection(-1);
        player.play();
        gsap.to(icon, {
          rotate: 0,
          duration: 0.3
        });
      },
    }).to(
      chars,
      {
        top: "-30px",
        stagger: {
          amout: 0.4,
          each: 0.05,
          onComplete: function (e) {
            gsap.set(this.targets()[0], { top: "30px" });
            gsap.to(this.targets()[0], {
              top: 0,
              duration: 0.3,
              ease: "back.out(2)",
            });
          },
        },
      },
      "<"
    );

    window.addEventListener("scroll", () => {
      if (
        !StickyTicketing.initAnimationIsPlaying &&
        !StickyTicketing.scrollAnimationIsPlaying
      ) {
        StickyTicketing.scrollAnimationIsPlaying = true
        tl.progress(0);
        tl.play().then( () => {
          setTimeout( () => {
            StickyTicketing.scrollAnimationIsPlaying = false
          }, SCROLL_TIMING)
        });
      }
    });
  }
};

export default StickyTicketing;
