'use strict';

import SplitType from "split-type";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Newsletter = {
    els: null,
    init: function () {
        Newsletter.els = document.querySelectorAll('.c-newsletter');
        if (Newsletter.els && Newsletter.els.length > 0) {
            Newsletter.els.forEach(el => {
                const toggleButtons = el.querySelectorAll('.email button');
                if (toggleButtons) {
                    toggleButtons.forEach(toggleButton=> {
                        toggleButton.addEventListener('click', () => {
                            el.classList.toggle('step2--');
                        });
                    });
                }
            });
        }

    },
};

export default Newsletter;
