const Popin = {
    buttons: null,
    popinElts: null,
    init: function () {
        Popin.popinElts = document.querySelectorAll('.c-popin');
        if (Popin.popinElts && Popin.popinElts.length > 0) {
            Popin.popinElts.forEach((elt) => {
                if (elt.querySelectorAll('.c-popin__close')) {
                    elt.querySelectorAll('.c-popin__close').forEach((btn) => {
                        btn.addEventListener('click', (e) => {
                            e.preventDefault();
                            Popin.closePopin(elt);
                        });
                    })
                }

                if (elt.querySelector('.c-popin__overlay')) {
                    elt.querySelector('.c-popin__overlay').addEventListener('click', (e) => {
                        e.preventDefault();
                        Popin.closePopin(elt);
                    });
                }
            });
        }
    },
    showPopin: function (elt) {
        elt.classList.add('open--');
    },
    closePopin: function (elt) {
        elt.classList.remove('open--');
    }
};

export default Popin;