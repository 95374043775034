'use strict';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const EventExperience = {
    els: null,
    init: function () {
        EventExperience.els = [...document.querySelectorAll('.f-event'), ...document.querySelectorAll('.f-experience')]

        if (EventExperience.els && EventExperience.els.length > 0) {
            gsap.utils.toArray(EventExperience.els).forEach(function(elem) {
                gsap.to(elem.querySelectorAll('.js-fadeUp'), {
                    opacity:1,
                    y:0,
                    ease: "power4",
                    stagger:0.25,
                    duration:.2,
                    delay:.25
                });

                gsap.to(elem.querySelector('.c-button-ctn'), {
                    opacity:1,
                    x:0,
                    ease: "power4",
                    duration:.25,
                    delay:.3
                });
            });
        }
    },
};

export default EventExperience;
