"use strict";

import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

const Immersion = {
  el: null,
  button: null,
  init: function () {
    Immersion.el = document.querySelector(".f-immersion");
    Immersion.buttonContainer = document.querySelector(".f-immersion__cta");
    Immersion.button = document.querySelector(".f-immersion__cta button");

    if (Immersion.el) {
      gsap.registerPlugin(ScrollTrigger);
      Immersion.initScrollAnimation();
    }

    if (Immersion.button && Immersion.buttonContainer) {
      Immersion.el.addEventListener("pointermove", (e) => Immersion.animateButtonFollow(e));
      Immersion.el.addEventListener("mouseenter", Immersion.animateButtonEnter)
      Immersion.el.addEventListener("mouseleave", Immersion.animationButtonLeave)    }
  },
  initScrollAnimation: function () {
    const mask = document.querySelector(".f-immersion__video-mask");
    const text = document.querySelector(".f-immersion__content")

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: Immersion.el,
        pin: true, 
        scrub: 1
      },
    });

    tl.to(mask, {
      scale: 20,
      opacity: 0,
      delay: 0.2, 
      duration: 2,
    });
    tl.to(text, {
        opacity: 1,
    },'-=1.5')
  },
  animateButtonFollow: function (e) {
    const sizes = Immersion.getSizes(Immersion.buttonContainer, Immersion.button);

    let posX = ((e.offsetX - 0.5 * sizes.button.width) * 100) / sizes.parent.width;
    let posY = ((e.offsetY - 0.5 * sizes.button.height) * 100) / sizes.parent.height;

    gsap.to(Immersion.button, {
      left: `${posX}%`,
      top: `${posY}%`,
      duration: 0.5,
      ease: "linear",
    });
  }, 
  animateButtonEnter: function () {
    gsap.to(Immersion.buttonContainer, {
      autoAlpha: 1
    })
  }, 
  animationButtonLeave: function () {
    gsap.to(Immersion.buttonContainer, {
      autoAlpha: 0
    })
  },
  getSizes: function (parent, button) {
    return {
      button: {
        width: parseFloat(getComputedStyle(button).width),
        height: parseFloat(getComputedStyle(button).height),
      },
      parent: {
        width: parseFloat(getComputedStyle(parent).width),
        height: parseFloat(getComputedStyle(parent).height),
      },
    };
  },
};

export default Immersion;
