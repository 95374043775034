"use strict";

// core version + modules:
import Swiper, { Navigation, Scrollbar, FreeMode, Mousewheel } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const SliderMulti = {
  els: null,
  init: function () {
    SliderMulti.els = document.querySelectorAll(".f-slider.multi--");
    if (SliderMulti.els && SliderMulti.els.length > 0) {
      SliderMulti.els.forEach((el) => {
        const slides = el.querySelectorAll(".swiper-slide");
        SliderMulti.create(el, slides.length);
      });
    }
  },
  create: function (el, slidesLength) {
    const slider = el.querySelector(".swiper");
    let swiper = null;

    let modules = "";
    let navigation = null;
    let scrollbar = null;

    swiper = new Swiper(slider, {
      modules: [Navigation, FreeMode, Mousewheel],
      touchStartPreventDefault: false,
      spaceBetween: 0,
      slidesPerView: "auto",
      loop: false,
      speed: 600,
      autoHeight: false,
      navigation: {
        prevEl: ".f-slider__navigation-prev",
        nextEl: ".f-slider__navigation-next",
      },
      mousewheel: {
        forceToAxis: true,
        sensitivity: 1,
      },
      scrollbar: {
        hide: true,
      },
      freeMode: {
        enabled: true,
        momentum: true,
      },
    });
  },
};

export default SliderMulti;
