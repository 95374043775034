'use strict';

const axios = require('axios');
import {slideDown, slideUp} from "../utils/slide-toggle";

const Form = {
    els: null,
    pwds: null,
    errorOnChange: false,//passer a true si l'on souhaite afficher les msg au fur et a mesure de la saisie
    init: function () {
        const lang = document.documentElement.lang.substr(0, 2);

        Form.els = document.querySelectorAll('.c-form:not([data-nojs])');
        if (Form.els && Form.els.length > 0) {
            Form.els.forEach(form => {

                let url = '';

                if (form.dataset.type === 'newsletter') {
                    if (process.env.MIX_NEWSLETTER && lang) {
                        url = window.location.origin + '/wp-json/' + process.env.MIX_NEWSLETTER + "?lang=" + lang;
                    }
                }

                if(form.dataset.type === 'contact'){
                    if (process.env.MIX_CONTACT && lang) {
                        url=window.location.origin + '/wp-json/' + process.env.MIX_CONTACT + '?lang=' + lang
                    }                   
                }

                Form.formatForm();
                Form.manageForm(form, url);
            });
        }
    },
    formatForm: function () {
        document.querySelectorAll('.dateFormat').forEach((elt) => {
            elt.addEventListener('keyup', (e) => {
                if (elt.value.length == 2 && e.keyCode != "8") {
                    elt.value = elt.value + '/';
                }
            })
        });
    },
    manageForm: function (form, url) {
        if (form) {
            let inputs = form.querySelectorAll('.form-group input, .form-group textarea, .form-group select');

            const birthInput = form.querySelector('input[name="birthDate"]');
            if (birthInput) {
                let eventList = ['keyup', 'keydown'];
                for (event of eventList) {
                    birthInput.addEventListener(event, function (e) {
                        //To accomdate for backspacing, we detect which key was pressed - if backspace, do nothing:
                        if (e.key >= 0 && e.key <= 9) {
                            let numChars = birthInput.value.length;
                            if (numChars === 2 || numChars === 5) {
                                let thisVal = birthInput.value;
                                thisVal += '/';
                                birthInput.value = thisVal;
                            }
                        } else {
                            if (e.keyCode !== 8 && e.keyCode !== 9) {
                                e.preventDefault();
                            }
                        }
                    });
                }

            }


            if (Form.errorOnChange) {
                for (let i = 0; i < inputs.length; i++) {
                    const elementError = inputs[i].closest('.form-group').querySelector('.error');

                    let compareInputs = false;
                    let eventList = ["change", "keyup", "paste", "input", "propertychange"];
                    for (evt of eventList) {
                        // inputs[i].addEventListener(event, function () {
                        form.addEventListener(evt, function () {

                            if (form.querySelector('.error.global--') && form.querySelector('.error.global--').classList.contains('active')) {
                                form.querySelector('.error.global--').classList.remove('active');
                            }
                            // Each time the user types something, we check if the
                            // form fields are valid.
                            if (inputs[i].dataset.compare) {
                                if (inputs[i].value !== "") {
                                    if (compareInputs === false) {
                                        compareInputs = true;
                                    }
                                }
                            }

                            if (inputs[i].dataset.condition) {
                                const targetElName = inputs[i].dataset.condition;
                                const targetEl = form.querySelector('[name="' + targetElName + '"]');
                                const targetElRow = targetEl.closest('.form-row');
                                if (targetEl) {
                                    if (inputs[i].tagName == "INPUT") {
                                        if (inputs[i].type == "text") {
                                            // alert("this is Text Box.")
                                            if (inputs[i].value !== null && inputs[i].value !== '') {
                                                targetElRow.dataset.visible = true;
                                            } else {
                                                targetElRow.dataset.visible = false;
                                            }
                                        } else if (inputs[i].type == "checkbox") {
                                            // alert("this is CheckBox.")
                                            if (inputs[i].checked === true) {
                                                targetElRow.dataset.visible = true;
                                            } else {
                                                targetElRow.dataset.visible = false;
                                            }
                                        } else if (inputs[i].type == "radio") {
                                            if (inputs[i].checked === true) {
                                                targetElRow.dataset.visible = true;
                                            } else {
                                                targetElRow.dataset.visible = false;
                                            }
                                        }
                                    }
                                    if (inputs[i].tagName == "SELECT") {
                                        // alert("this is Select")
                                        if (inputs[i].value !== null && inputs[i].value !== '') {
                                            targetElRow.dataset.visible = true;
                                        } else {
                                            targetElRow.dataset.visible = false;
                                        }
                                    }
                                }
                            }

                            // Each time the user types something, we check if the
                            // form fields are valid.
                            if (!inputs[i].validity.valid) {
                                // If there is still an error, show the correct error
                                Form.showError(form, inputs[i], elementError);
                            } else {
                                // In case there is an error message visible, if the field
                                // is valid, we remove the error message.
                                if (elementError) {
                                    elementError.textContent = ''; // Reset the content of the message
                                }

                                inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                if (inputs[i].dataset.compare) {
                                    if (compareInputs === false) {
                                        // If there is still an error, show the correct error
                                        Form.showError(form, inputs[i], elementError);
                                    } else {
                                        let otherInput = form.querySelectorAll('[data-compare="true"]');
                                        otherInput.forEach(input => {
                                            const elementError = input.closest('.form-group').querySelector('.error');
                                            elementError.textContent = ''; // Reset the content of the message
                                            input.closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                        });
                                    }
                                }
                                if (inputs[i].dataset.password) {
                                    const str = inputs[i].value;
                                    //  console.log(str)
                                    if (str.match(/[0-9]/g) && str.match(/[A-Z]/g) && str.match(/[a-z]/g) && str.match(/[^a-zA-Z\d]/g) && str.length >= 8) {
                                        inputs[i].closest('.form-group').querySelector('.error').textContent = ''; // Reset the content of the message
                                        inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                    } else {
                                        Form.showError(form, inputs[i], inputs[i].closest('.form-group').querySelector('.error'));
                                    }
                                }
                                if (inputs[i].dataset.condition) {
                                    const targetElName = inputs[i].dataset.condition;
                                    const targetEl = form.querySelector('[name="' + targetElName + '"]');
                                    if (!targetEl.value && targetEl.value === "") {
                                        Form.showError(form, targetEl, targetEl.closest('.form-group').querySelector('.error'));
                                    } else {
                                        targetEl.closest('.form-group').querySelector('.error').textContent = ''; // Reset the content of the message
                                        targetEl.closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                    }
                                }
                                // if (inputs[i].dataset.hassamevalue) {
                                //     if (document.getElementById(inputs[i].dataset.hassamevalue).value != inputs[i].value) {
                                //         Form.showError(form, document.getElementById(inputs[i].dataset.hassamevalue), document.getElementById(inputs[i].dataset.hassamevalue).closest('.form-group').querySelector('.error'));
                                //     } else {
                                //         document.getElementById(inputs[i].dataset.hassamevalue).closest('.form-group').querySelector('.error').textContent = ''; // Reset the content of the message
                                //         document.getElementById(inputs[i].dataset.hassamevalue).closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                //     }
                                // }

                                if (inputs[i].dataset.samevalue) {
                                    // console.log(inputs[i])
                                    if (document.getElementById(inputs[i].dataset.samevalue).value != inputs[i].value) {
                                        Form.showError(form, inputs[i], elementError);
                                    } else {
                                        elementError.textContent = ''; // Reset the content of the message
                                        inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                    }
                                }


                                if (inputs[i].dataset.checkcb) {
                                    const str = inputs[i].value.replace(/ /g, '');
                                    if (str.match(inputs[i].dataset.checkcb)) {
                                        inputs[i].closest('.form-group').querySelector('.error').textContent = ''; // Reset the content of the message
                                        inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                    } else {
                                        Form.showError(form, inputs[i], inputs[i].closest('.form-group').querySelector('.error'));
                                    }
                                }
                            }
                        });
                    }
                }
            }

            if (form.querySelector('.js-submit')) {
                form.querySelector('.js-submit').addEventListener('click', function (e) {
                    if (form.dataset.type !== 'submitForm') {
                        e.preventDefault();
                    }
                    let validArr = [];
                    for (let i = 0; i < inputs.length; i++) {
                        const elementError = inputs[i].closest('.form-group').querySelector('.error');
                        if (!inputs[i].validity.valid) {
                            // If it isn't, we display an appropriate error message
                            Form.showError(form, inputs[i], elementError);
                            validArr.push(false);
                        } else {
                            //console.log('valid field', inputs[i]);
                            if (inputs[i].dataset.compare) {
                                if (compareInputs === false) {
                                    Form.showError(form, inputs[i], elementError);
                                    validArr.push(false);
                                } else {
                                    validArr.push(true);
                                    elementError.textContent = ''; // Reset the content of the message
                                    inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                }
                            } else if (inputs[i].dataset.samevalue) {
                                if (document.getElementById(inputs[i].dataset.samevalue).value != inputs[i].value) {
                                    Form.showError(form, inputs[i], elementError);
                                    validArr.push(false);
                                } else {
                                    elementError.textContent = ''; // Reset the content of the message
                                    inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                }
                            } else if (inputs[i].dataset.checkcb) {
                                const str = inputs[i].value.replace(/ /g, '');
                                if (!str.match(inputs[i].dataset.checkcb)) {
                                    Form.showError(form, inputs[i], elementError);
                                    validArr.push(false);
                                } else {
                                    validArr.push(true);
                                    elementError.textContent = ''; // Reset the content of the message
                                    inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                                }
                            } else {
                                validArr.push(true);
                                elementError.textContent = ''; // Reset the content of the message
                                inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                            }
                        }
                    }

                    if (validArr.includes(false)) {
                        // Then we prevent the form from being sent by canceling the event
                        e.preventDefault();
                    } else {
                        if (form.querySelector('.global--')) {
                            form.querySelector('.global--').classList.remove('active');
                        }
                        if (document.querySelector('.c-stickyErrorGlobal')) {
                            if (document.querySelector('.c-stickyErrorGlobal .global--')) {
                                document.querySelector('.c-stickyErrorGlobal .global--').classList.remove('active');
                            }
                        }

                        if (url) {
                            form.querySelector('.js-submit').classList.add('loading');
                            if (form.querySelector('.global-- span')) {
                                form.querySelector('.global-- span').textContent = '';
                            }
                            if (document.querySelector('.c-stickyErrorGlobal')) {
                                if (document.querySelector('.c-stickyErrorGlobal .global--')) {
                                    document.querySelector('.c-stickyErrorGlobal .global--').querySelector('.global-- span').textContent = '';
                                }
                            }
                            let formData = new FormData(form);
                            let params = '';

                            axios({
                                method: "post",
                                url: url + params,
                                data: formData,
                                headers: {"Content-Type": "multipart/form-data"},
                            }).then(function (response) {
                                    //handle success
                                    form.querySelector('.js-submit').classList.remove('loading');
                                    if (response.data.success === true || (response.data.data && response.data.data.status === 200)) {
                                        if (form.querySelector('.success')) {
                                            let successMsg = ''
                                            if(response.data.data && response.data.data.msg){
                                                successMsg = response.data.data.msg
                                            }
                                            else if (response.data.message) {
                                                successMsg = response.data.message
                                            }
                                            form.querySelector('.success').innerHTML = successMsg + '<i class="icon-success"></i>';
                                            form.querySelector('.success').classList.add('active');
                                        }
                                        form.querySelector('.form-group.submit').classList.add('sent');
                                        form.reset();
                                    } else if (response.data.success === false || response.data.data.status !== 200) {
                                        form.querySelector('.global--').classList.add('active');
                                        let msg = '';
                                        if (response.data.message) {
                                            if (response.data.message.errors) {
                                                let arr = response.data.message.errors.fields;
                                                if (arr) {
                                                    if (!Array.isArray(response.data.message.errors.fields)) {
                                                        arr = Object.entries(response.data.message.errors.fields).map((e) => ({[e[0]]: e[1]}));
                                                    }
                                                    arr.forEach(item => {
                                                        Object.keys(item).forEach((key) => {
                                                            msg += ' ' + item[key];
                                                            const formGroup = form.querySelector('[name="' + key + '"]').closest('.form-group');
                                                            if (formGroup) {
                                                                formGroup.classList.add('error');
                                                                formGroup.querySelector('.error').innerHTML = item[key];
                                                            }
                                                        });
                                                    });

                                                    console.log('errors', arr);
                                                }
                                            } else {
                                                msg = response.data.message[Object.keys(response.data.message)[0]];
                                            }
                                        }
                                        //form.querySelector('.global-- span').innerHTML = msg;
                                        if (document.querySelector('.c-stickyErrorGlobal')) {
                                            const stickyError = form.querySelector('.global--').closest('.c-stickyError');
                                            if (stickyError) {
                                                document.querySelector('.c-stickyErrorGlobal').innerHTML = '';
                                                document.querySelector('.c-stickyErrorGlobal').appendChild(stickyError.cloneNode(true));
                                                stickyError.querySelector('.global--').classList.remove('active');
                                            }
                                        }
                                        setTimeout(() => {
                                            document.querySelector('.global--.active').classList.remove('active');
                                        }, 5000);
                                    } else {
                                        if (form.querySelector('.global--')) {
                                            form.querySelector('.global--').classList.add('active');
                                            //form.querySelector('.global-- span').innerHTML = response.data.data.errors.global;
                                        }
                                        setTimeout(() => {
                                            document.querySelector('.global--.active').classList.remove('active');
                                        }, 5000);
                                    }
                                }
                            ).catch(function (response) {
                                //handle error
                                console.log('err', response);

                                form.querySelector('.js-submit').classList.remove('loading');
                                if (form.querySelector('.global--')) {
                                    form.querySelector('.global--').classList.add('active');
                                }
                                if (document.querySelector('.c-stickyErrorGlobal')) {
                                    const stickyError = form.querySelector('.global--').closest('.c-stickyError');
                                    if (stickyError) {
                                        document.querySelector('.c-stickyErrorGlobal').innerHTML = '';
                                        document.querySelector('.c-stickyErrorGlobal').appendChild(stickyError.cloneNode(true));
                                        stickyError.querySelector('.global--').classList.remove('active');
                                    }
                                }
                                form.reset();
                            });

                        }
                    }

                });
            }
        }
    },
    showError: function (form, element, elementError) {
        if (element.validity.valueMissing) {
            // If the field is empty,
            // display the following error message.
            elementError.textContent = form.querySelector('.error-message-required').textContent;
        } else if (element.validity.typeMismatch) {
            // If the field doesn't contain an email address,
            // display the following error message.
            if (element.dataset.alphabet) {
                elementError.textContent = form.querySelector('.error-message-invalid-alphabet').textContent;
            } else if (element.dataset.email) {
                elementError.textContent = form.querySelector('.error-message-invalid-email').textContent;
            } else if (element.dataset.phone) {
                elementError.textContent = form.querySelector('.error-message-invalid-phone').textContent;
            } else {
                elementError.textContent = form.querySelector('.error-message-invalid').textContent;
            }
        } else if (element.validity.tooLong) {
            // If the data is too long,
            // display the following error message.
            elementError.textContent = form.querySelector('.error-message-toolong').textContent.replace('[MAXLENGTH]', element.dataset.maxlength);
        } else if (element.validity.tooShort) {
            // If the data is too short,
            // display the following error message.
            elementError.textContemnt = form.querySelector('.error-message-tooshort').textContent;
        } else if (element.validity.patternMismatch) {
            elementError.textContent = form.querySelector('.error-message-invalid').textContent;
        } else if (element.dataset.compare) {
            elementError.textContent = form.querySelector('.error-message-compare').textContent;
        } else if (element.dataset.samevalue) {
            elementError.textContent = form.querySelector('.error-message-samevalue').textContent;
        } else if (element.dataset.password) {
            elementError.textContent = form.querySelector('.error-message-password').textContent;
        } else if (element.dataset.checkcb) {
            elementError.textContent = form.querySelector('.error-message-invalid').textContent;
        }

        // Set the styling appropriately
        element.closest('.form-group').classList.add('error');
    }
};

export default Form;